import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgFormLabel, ArgInputText, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import { FullOntology, FullOntologyObjectType } from '../../types';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './rename-object-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.rename-object-modal.title',
        defaultMessage: 'Rename object',
    },
    fieldName: {
        id: 'settings.rename-object-modal.field.name',
        defaultMessage: 'Object name',
    },
    submit: {
        id: 'settings.rename-object-modal.submitButton',
        defaultMessage: 'Rename',
    },
    cancel: {
        id: 'settings.rename-object-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    renameVertexError: {
        id: 'settings.rename-object-modal.renameVertexError',
        defaultMessage: 'Something went wrong while trying to rename the object',
    },
    required: {
        id: 'settings.rename-object-modal.required',
        defaultMessage: 'Required',
    },
});

export interface RenameObjectModalProps {
    closeModal: () => void;
    vertex: FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function RenameObjectModal({
    closeModal,
    vertex,
    ontology,
    setOntology,
}: RenameObjectModalProps) {
    const classNames = useClassNames('rename-object-modal');
    const notifications = useArgNotifications();

    const [objectName, setObjectName] = useState<string>(vertex.displayName);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setObjectName(vertex.displayName);
    }, [vertex]);

    const renameObject = async () => {
        setLoading(true);

        try {
            await ontologiesConnector.editOntologyObject({
                ontologyId: ontology.id,
                name: vertex.name,
                newDisplayName: objectName,
                newProperties: vertex.properties,
            });
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
            setLoading(false);
        } catch (e) {
            notifications.snackError({ message: messages.renameVertexError }, e as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='large'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.submit}
                        data-testid='rename'
                        disabled={loading || objectName === ''}
                        loading={loading}
                        onClick={() => {
                            renameObject();
                            closeModal();
                        }}
                    />
                </div>
            }
        >
            <form autoComplete='off'>
                <ArgFormLabel
                    propertyName={messages.fieldName}
                    required={messages.required}
                >
                    <ArgInputText
                        value={objectName}
                        onChange={(input) => setObjectName(input ?? '')}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
