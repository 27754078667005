import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { ControlProps } from './controls-type';
import { ArgChangeReason, ArgCombo, ArgDivider, ArgInputDndConfig, ArgInputText, useClassNames } from '../../basic';
import { UndefinedButton } from './undefined-button';
import { DataType } from '../data-types';
import { DroppedParameter } from './dropped-parameter';
import { ControlParameter } from './control-parameter';

import './text-control.less';

type OperatorType = 'positive' | 'negative';

export type Condition =
    'Contains'
    | 'Empty'
    | 'EndsWith'
    | 'Matches'
    | 'StartsWith'
    | 'SoundsLike'
    | 'DoesNotContain'
    | 'NotEmpty'
    | 'DoesNotEndWith'
    | 'DoesNotMatchWith'
    | 'DoesNotSoundLike'
    | 'DoesNotStartWith';

export interface TextSearch {
    condition?: Condition;
    values: (string | null)[];
    parameter?: ControlParameter;
}

const messages = defineMessages({
    inputPlaceholder: {
        id: 'common.controls.text.control.InputPlaceholder',
        defaultMessage: 'Write a description',
    },
    comboPlaceholder: {
        id: 'common.controls.text.control.SearchPlaceholder',
        defaultMessage: 'Select an operator',
    },
    switchNegativeWording: {
        id: 'common.controls.text.control.switch.wording',
        defaultMessage: 'Switch to negative wording',
    },
    dropParameter: {
        id: 'common.controls.text.control.DropParameter',
        defaultMessage: 'Drop the parameter here',
    },
});

const operators: Record<OperatorType, Condition[]> = {
    positive: ['Contains', 'Empty', 'EndsWith', 'Matches', 'SoundsLike', 'StartsWith'],
    negative: ['DoesNotContain', 'NotEmpty', 'DoesNotEndWith', 'DoesNotMatchWith', 'DoesNotSoundLike', 'DoesNotStartWith'],
};

const formattedOperators: Record<Condition, MessageDescriptor> = defineMessages({
    'Contains': {
        id: 'common.controls.operators.Contains',
        defaultMessage: 'Contains',
    },
    'Empty': {
        id: 'common.controls.operators.Empty',
        defaultMessage: 'Empty',
    },
    'EndsWith': {
        id: 'common.controls.operators.EndsWith',
        defaultMessage: 'Whose word ends with',
    },
    'Matches': {
        id: 'common.controls.operators.Matches',
        defaultMessage: 'Matches',
    },
    'StartsWith': {
        id: 'common.controls.operators.StartsWith',
        defaultMessage: 'Whose word begins with',
    },
    'SoundsLike': {
        id: 'common.controls.operators.SoundsLike',
        defaultMessage: 'Sounds like',
    },
    'DoesNotContain': {
        id: 'common.controls.operators.DoesNotContains',
        defaultMessage: 'Does not contain',
    },
    'NotEmpty': {
        id: 'common.controls.operators.NotEmpty',
        defaultMessage: 'Not empty',
    },
    'DoesNotEndWith': {
        id: 'common.controls.operators.DoesNotEndWith',
        defaultMessage: 'Does not end with',
    },
    'DoesNotMatchWith': {
        id: 'common.controls.operators.DoesNotMatchWith',
        defaultMessage: 'Does not match with',
    },
    'DoesNotSoundLike': {
        id: 'common.controls.operators.DoesNotSoundLike',
        defaultMessage: 'Does not sound like',
    },
    'DoesNotStartWith': {
        id: 'common.controls.operators.DoesNotStartWith',
        defaultMessage: 'Does not start with',
    },
});

const enabled: Partial<Record<Condition, boolean>> = {
    'Contains': true,
    'StartsWith': true,
    'EndsWith': true,
    'DoesNotContain': true,
    'DoesNotStartWith': true,
    'DoesNotEndWith': true,
};

const DEFAULT_OPERATOR = 'StartsWith';

export function TextControl(props: ControlProps<TextSearch>) {
    const {
        className,
        value: externalValue,
        onChange,
        onReset,
        showUndefined,
        propertyInfo,
        autoFocus,
        acceptsParameters,
        readOnly,
    } = props;

    const classNames = useClassNames('common-textControl');

    const { formatMessage } = useIntl();

    const [containsNegativeWording, setContainsNegativeWording] = useState<OperatorType>('positive');

    let value: TextSearch | null | undefined = externalValue;
    if (!value) {
        value = {
            condition: DEFAULT_OPERATOR,
            values: [],
        };
    }

    let hasUndefined = false;
    let inputValue = '';

    const values = value.values;
    if (values?.length) {
        for (const v of values) {
            if (v === null) {
                hasUndefined = true;
                continue;
            }
            inputValue = v;
            break;
        }
    }

    const handleSwitchWording = useCallback(() => {
        setContainsNegativeWording((prevState) => {
            return (prevState === 'positive' ? 'negative' : 'positive');
        });
    }, [setContainsNegativeWording]);

    const handleApply = useCallback((text: string | null, reason: ArgChangeReason) => {
        text = text || '';

        if (!value?.condition) {
            return;
        }

        const values: (string | null)[] = [text];
        if (hasUndefined) {
            values.push(null);
        }

        onChange((prev) => {
            return {
                ...prev,
                condition: value!.condition,
                values,
            };
        }, reason);
    }, [hasUndefined, onChange, value]);

    const handleOperatorChanged = useCallback((condition: Condition | undefined, reason: ArgChangeReason) => {
        if (!condition) {
            if (hasUndefined) {
                onChange((prev) => ({ ...prev, values: [null] }), reason);

                return;
            }
            onChange(null, reason);

            return;
        }

        const values: (string | null)[] = [inputValue];
        if (hasUndefined) {
            values.push(null);
        }
        onChange((prev) => ({
            ...prev,
            condition,
            values,
        }), reason);
    }, [hasUndefined, inputValue, onChange]);

    const handleUndefinedChange = useCallback((enabled: boolean) => {
        const values: (string | null)[] = [inputValue];
        if (enabled) {
            values.push(null);
        }

        onChange((prev) => {
            return {
                ...prev,
                condition: value!.condition,
                values,
            };
        }, 'selection');
    }, [inputValue, onChange, value]);

    const handleParameterChange = useCallback((type: string, parameter: ControlParameter | undefined) => {
        onChange((prev) => {
            const condition = prev?.condition ?? DEFAULT_OPERATOR;
            if (prev) {
                return {
                    ...prev,
                    parameter,
                    condition,
                };
            }

            return {
                values: [],
                parameter,
                condition,
            };
        }, 'selection');
    }, [onChange]);

    const enabledOperators = useMemo<Condition[]>(() => {
        const enabledOperators = operators[containsNegativeWording]
            .filter((op: Condition) => formattedOperators[op] && enabled[op]);

        enabledOperators.sort((o1, o2) => {
            const fo1 = formattedOperators[o1];
            const fo2 = formattedOperators[o2];

            const l1 = formatMessage(fo1);
            const l2 = formatMessage(fo2);

            return l1.localeCompare(l2);
        });

        return enabledOperators;
    }, [containsNegativeWording]);

    const handleDropDownRender = useCallback((): ReactNode => {
        const invertedWording = (containsNegativeWording === 'negative') ? 'positive' : 'negative';
        const invertedEnabledOperators = operators[invertedWording]
            .filter((op) => formattedOperators[op] && enabled[op]);

        if (!invertedEnabledOperators.length) {
            return false;
        }

        return <div className={classNames('&-dropDown')} data-testid='arg-TextControl-dropDown-test'>
            <button
                data-testid='switch-wording-test'
                type='button'
                className={classNames('arg-menu-item', 'switch-wording', { clicked: containsNegativeWording === 'negative' })}
                onClick={handleSwitchWording}
                disabled={readOnly}
            >
                <span className='switch-wording-icon' />
                <span className='switch-wording-title'>
                    <FormattedMessage {...messages.switchNegativeWording} />
                </span>
            </button>
            <ArgDivider className={classNames('&-dropDown-divider')} />
        </div>;
    }, [classNames, containsNegativeWording, handleSwitchWording, readOnly]);

    const getConditionLabel = useCallback((item: Condition) => {
        return formattedOperators[item];
    }, []);

    const dndConfig = useMemo<ArgInputDndConfig<ControlParameter>>(() => ({
        allowedDndTypes: acceptsParameters ? acceptsParameters(DataType.String) : [],
        allowDrop: acceptsParameters && !value?.parameter && !readOnly,
        renderDroppedItem: ({ type, data }) =>
            <DroppedParameter
                item={data}
                onRemove={() => handleParameterChange(type, undefined)}
                readonly={readOnly}
            />,
        onDrop: handleParameterChange,
        droppedItem: value?.parameter && { data: value.parameter, type: DataType.String },
        dropPlaceholder: messages.dropParameter,
    }), [acceptsParameters, handleParameterChange, value.parameter, readOnly]);

    return (
        <>
            <ArgCombo<Condition>
                className={classNames('&', className)}
                size='medium'
                cardinality='one'
                data-testid='text-control-conditions'
                items={enabledOperators}
                getItemLabel={getConditionLabel}
                topRender={handleDropDownRender}
                placeholder={messages.comboPlaceholder}
                onChange={handleOperatorChanged}
                value={value.condition}
                disabled={readOnly}
            />
            {
                value.condition && (
                    <ArgInputText
                        className={classNames('&-input')}
                        autoFocus={autoFocus}
                        size='medium'
                        data-testid='text-control-input'
                        initialValue={inputValue}
                        placeholder={messages.inputPlaceholder}
                        onInputChange={onReset}
                        onChange={handleApply}
                        dndConfig={dndConfig}
                        readOnly={readOnly}
                    />)
            }
            {showUndefined !== false &&
                <UndefinedButton
                    className={classNames('&-undefined-button')}
                    value={hasUndefined}
                    onChange={handleUndefinedChange}
                    count={propertyInfo?.numberOfMissing}
                    disabled={readOnly} />}
        </>
    );
}
