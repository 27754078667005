import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgButton, ArgInputText, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import { FullOntology, FullOntologyLinkType } from '../../types';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { ArgFormLabel } from '../../../../../components/basic';

import './rename-edge-modal.less';


export const messages = defineMessages({
    title: {
        id: 'settings.rename-edge-modal.title',
        defaultMessage: 'Rename relation',
    },
    fieldName: {
        id: 'settings.rename-edge-modal.field.name',
        defaultMessage: 'Relation name',
    },
    submit: {
        id: 'settings.rename-edge-modal.submitButton',
        defaultMessage: 'Rename',
    },
    cancel: {
        id: 'settings.rename-edge-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    renameEdgeError: {
        id: 'settings.rename-edge-modal.renameEdgeError',
        defaultMessage: 'Something went wrong while trying to rename the edge',
    },
    required: {
        id: 'settings.rename-edge-modal.required',
        defaultMessage: 'Required',
    },
});

export interface RenameEdgeModalProps {
    closeModal: () => void;
    edge: FullOntologyLinkType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function RenameEdgeModal(props: RenameEdgeModalProps) {
    const {
        closeModal,
        edge,
        ontology,
        setOntology,
    } = props;

    const notifications = useArgNotifications();
    const classNames = useClassNames('rename-edge-modal');

    const [edgeDisplayName, setEdgeDisplayName] = useState<string>(edge.displayName);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEdgeDisplayName(edge.displayName);
    }, [edge]);

    const renameEdge = async () => {
        setLoading(true);

        try {
            await ontologiesConnector.editOntologyEdge({
                ontologyId: ontology.id,
                name: edge.name,
                newDisplayName: edgeDisplayName,
                newProperties: edge.properties,
            });
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
            setLoading(false);
            setEdgeDisplayName('');
            closeModal();
        } catch (e) {
            notifications.snackError({ message: messages.renameEdgeError }, e as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='large'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.submit}
                        data-testid='rename'
                        disabled={edgeDisplayName === '' || loading}
                        onClick={() => {
                            renameEdge();
                        }}
                    />
                </div>
            }
        >
            <form autoComplete='off'>
                <ArgFormLabel
                    propertyName={messages.fieldName}
                    required={messages.required}
                >
                    <ArgInputText
                        value={edgeDisplayName}
                        onChange={(input) => setEdgeDisplayName(input ?? '')}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
