import { ArgUserInfo } from '../../components/basic';
import { UniverseId } from '../../exploration/model/universe';
import { OntologyId } from '../universes/ontology/types';
import { OntologyVersion } from './dtoApi';

export interface RawOntology {
    id: OntologyId;
    name: string;
    description?: string;
    universeIds: UniverseId[];
    createdDate: string;
    createdBy: ArgUserInfo;
    lastUpdatedDate: string;
    lastUpdatedBy: ArgUserInfo;
};

export interface RawDetailedOntology extends RawOntology {
    lastPublishedDate: string;
    lastPublishedBy: ArgUserInfo;
    version: OntologyVersion;
}

export interface RawUniverseKBFeedingData {
    current?: RawFeedRunDetails;
    previous?: RawFeedRunDetails;
}

export interface RawFeedRunDetails {
    dataChangesStartDate?: string;
    dataChangesEndDate?: string;
    runStartDate?: string;
    runEndDate?: string;
    loadingGroupsDetails: RawLoadingGroupDetails[];
}

export enum RawLoadingGroupStatus {
    Pending = 'Pending',
    Loading = 'Loading',
    Done = 'Done',
    Failed = 'Failed',
}

export enum RawLoadingGroupIdentifier {
    KbqlVertex = 'Kbql-Vertex',
    KbqlEdge = 'Kbql-Edge',
}

interface RawLoadingGroupDetails {
    identifier: RawLoadingGroupIdentifier;
    status: RawLoadingGroupStatus;
    progress: number;
}
