import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from '../../../../../components/basic';
import { UniverseKBFeedingCurrentSynchronization } from '../../../../models/dtoApi';
import { KBFeedingPropertyValue } from './kb-feeding-property-value';
import { KBFeedingSynchronizationProcessMonitor } from './kb-feeding-process-monitor';
import { buildRangeDatesContent } from './utils';


import './kb-feeding-monitoring-current-synchronization.less';

const CLASSNAME = 'settings-kb-feeding-monitoring-current-synchronization';

const messages = defineMessages({
    rangeDatesTitle: {
        id: 'settings.kb-feeding-monitoring-panel.currentSynchronization.rangeDatesTitle',
        defaultMessage: 'Range updates dates',
    },
    objects: {
        id: 'settings.kb-feeding-monitoring-panel.currentSynchronization.objects',
        defaultMessage: 'Objects',
    },
    partialEdges: {
        id: 'settings.kb-feeding-monitoring-panel.currentSynchronization.partialEdges',
        defaultMessage: 'Partial links',
    },
    fullEdges: {
        id: 'settings.kb-feeding-monitoring-panel.currentSynchronization.fullEdges',
        defaultMessage: 'Full links',
    },
});

interface KBFeedingCurrentSynchronizationProps {
    className?: ClassValue;
    currentSynchronization: UniverseKBFeedingCurrentSynchronization;
}

export function KBFeedingCurrentSynchronization({
    className,
    currentSynchronization,
}: KBFeedingCurrentSynchronizationProps) {
    const classNames = useClassNames(CLASSNAME);

    const rangeDatesContent = buildRangeDatesContent(currentSynchronization.rangeDates);

    return (
        <div className={classNames('&', className)}>
            <KBFeedingPropertyValue propertyValue={{ label: messages.rangeDatesTitle, content: rangeDatesContent, key: 'rangeDates' }} />
            <KBFeedingSynchronizationProcessMonitor title={messages.objects} synchronizationProcess={currentSynchronization.object} />
            <KBFeedingSynchronizationProcessMonitor title={messages.partialEdges} synchronizationProcess={currentSynchronization.partialEdges} />
            <KBFeedingSynchronizationProcessMonitor title={messages.fullEdges} synchronizationProcess={currentSynchronization.fullEdges} />
        </div>
    );
};
